export const environment = {
  name: 'dev',
  production: false,
  apiServerUrl: 'https://tournity-api.dev.paywith.co.kr',
  serverUrl: '',
  wsServerUrl: 'wss://tournity-api.dev.paywith.co.kr/ws',
  searchParamKeyCamelize: '',
  version: 'v241101.d',
  brandId: 105,
};
