<div
  class="header-wrapper"
  [style.height.px]="height"
  ngClass.landscape="landscape"
>
  <div
    fxLayoutAlign="center center"
    fxLayoutGap="8px"
    class="header-left"
    #left
    [fxFlex]="titlePaddingLevel * titlePaddingUnit + 'px'"
  >
    <ng-content select="[left]"></ng-content>
    <button
      class="back"
      mat-icon-button
      (click)="onBackClick()"
      *ngIf="hasBack"
    >
      <img src="/assets/imgs/ic_back.svg" />
    </button>
  </div>
  <div fxFlex="grow" fxLayoutAlign="center center" class="header-center">
    <div class="header-center-title" fxLayoutAlign="center center">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    fxLayoutAlign="center center"
    fxLayoutGap="8px"
    class="header-right"
    #right
    [fxFlex]="titlePaddingLevel * titlePaddingUnit + 'px'"
  >
    <ng-content select="[right]"></ng-content>
  </div>
</div>
<div class="header-spacer" [style.padding-top.px]="height"></div>
