import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';
import { map, Observable } from 'rxjs';
import {
  PwEditorOption,
  PW_EDITOR_OPTION,
} from '../../../components/pw-summernote/pw-summernote.component';
import {
  JQuerySummernote,
  SummernoteStatic,
} from '../../../components/pw-summernote/summernote.interface';
import { ImageService } from '../../../services/image.service';
import { PW_SUPPORTED_LOCALES } from '../../pw-locale';
import { LocalePlugin } from './locale-plugin';

/**
 * Summernote 컴포넌트
 *
 * @privateRemarks
 * JQuery에 summernote를 추가하기 위해 angular.json 에서
 * projects._<프론트 프로젝트명>_.archtect.build.options 에
 * 다음 항목을 추가해야함:
 *
 * ```json
 *"scripts": [
 *  {
 *    "input": "node_modules/summernote/dist/summernote-lite.js",
 *    "inject": false,
 *    "bundleName": "summernote"
 *  },
 *  {
 *    "input": "node_modules/summernote/dist/lang/summernote-ko-KR.min.js",
 *    "inject": false,
 *    "bundleName": "summernote-ko-KR"
 *  },
 *  {
 *    "input": "node_modules/summernote/dist/lang/summernote-ja-JP.min.js",
 *    "inject": false,
 *    "bundleName": "summernote-ja-JP"
 *  }
 *]
 * ```
 */
@Component({
  selector: 'pw-locale-summernote',
  templateUrl: './pw-locale-summernote.component.html',
  styleUrls: ['./pw-locale-summernote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => PwLocaleSummernoteComponent),
    },
  ],
})
export class PwLocaleSummernoteComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('summernote') container: ElementRef<HTMLElement>;

  /**
   * 파일 업로드 경로. 서버에 file 키로 요청을 하면, 응답의 url키에 파일 경로가 있어야 한다
   */
  @Input() uploadUrl: string;

  /**
   * 첨부 이미지 최대 폭(px). 지정하면 이 폭 초과하는 이미지를 첨부할 때 이 폭으로 리사이징 한다
   */
  @Input() maxImgWidth: number;

  #currentLocale: { name: string; value: string };

  /** 현재 편집중인 다국어 */
  @Input() set currentLocale(v: { name: string; value: string }) {
    this.#currentLocale = v;
  }

  get currentLocale(): { name: string; value: string } {
    // 현재 선택한 언어가 없다면 첫번째 언어를 기본으로 설정
    if (!this.#currentLocale) {
      [this.#currentLocale] = this.supportedLocales;
    }

    return this.#currentLocale;
  }

  @Output() currentLocaleChange = new EventEmitter<{
    name: string;
    value: string;
  }>();

  /** formControl 값 중 현애 선택한 다국어에 맞는 텍스트 */
  currentValue: any = {};

  private disabled = false;

  private onChange: (value: any) => void;

  private onTouched: () => void;

  // 로드할 언어 + 로딩 중 작업
  private static loadedLangs: Map<string, Promise<void>> = new Map();

  private currentInit$: Promise<void>;

  constructor(
    @Optional()
    @Inject(PW_EDITOR_OPTION)
    private pwEditorOption: PwEditorOption,
    @Optional()
    @Inject(PW_SUPPORTED_LOCALES)
    private supportedLocales: { name: string; value: string }[],
    private zone: NgZone,
    private renderer2: Renderer2,
    private http: HttpClient,
    private imageService: ImageService
  ) {
    LocalePlugin.supportedLocales = supportedLocales;
  }

  ngOnInit(): void {
    this.uploadUrl =
      this.uploadUrl || this.pwEditorOption?.defaultFileServerUrl;

    this.currentInit$ = this.init$();
    LocalePlugin.currentLocale = this.currentLocale;
  }

  ngAfterViewInit(): void {
    this.currentInit$.then(() => {
      const summernoteStatic = <SummernoteStatic>(<any>$).summernote;
      summernoteStatic.plugins.locale = LocalePlugin;
      this.getJQueryInstance().summernote({
        toolbar: [
          // ['style', ['style']],
          [
            'font',
            [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'superscript',
              'subscript',
              'clear',
            ],
          ],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph', 'height']],
          ['table', ['table']],
          ['hr', ['hr']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['codeview', 'help']],
          ['locale', ['locale']],
        ],
        disableDragAndDrop: true,
        dialogsInBody: true,
        lang: this.getBrowserCultureLang(),
        callbacks: {
          onChange: (contents, instance: JQuerySummernote) => {
            this.onChangeCurrentValue(contents);
          },
          onFocus: () => {
            this.onTouched?.();
          },
          onImageUpload: (files: FileList) => {
            Array.from(files).forEach((file) => {
              this.addImageBlob(file).then((url) => {
                this.getJQueryInstance().summernote('insertImage', url);
              });
            });
          },
        },
      });
      const instance = this.getJQueryInstance();
      if (instance) {
        instance.on(
          'localeChanged',
          (event: JQuery.Event, locale: { name: string; value: string }) => {
            this.onChangeLocale(locale);
            instance?.summernote('code', this.getLocaleModel());
          }
        );
        if (this.currentValue) {
          instance.summernote('code', this.getLocaleModel());
        }
      }
      this.setDisabledState(this.disabled);
    });
  }

  ngOnDestroy(): void {
    this.getJQueryInstance()?.summernote('destroy');
  }

  /**
   * 입력란의 메뉴에서 변경할 다국어를 선택했을 때 동작.
   *
   * 현재 form 값 중 현재 선택한 다국어에 맞는 텍스트를 가져와서 입력란에 설정한다.
   *
   * 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
   * */
  onChangeLocale(locale: { name: string; value: string }): void {
    this.currentLocale = locale;

    // 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
    this.currentLocaleChange.emit(this.currentLocale);
  }

  /**
   * object 중 표시 언어에 맞는 값(object > ja 값 등) 가져오기
   */
  getLocaleModel(): string {
    const localeValue = this.currentLocale.value;
    if (!this.currentValue[localeValue]) {
      this.currentValue[localeValue] = '';
    }

    return this.currentValue[localeValue];
  }

  /**
   * 각 현지화 필드의 값이 변경되었을 때 동작. 다시 form value에 반영한다
   */
  onChangeCurrentValue(value: string): void {
    const localeValue = this.currentLocale.value;
    this.currentValue[localeValue] = value;

    this.onChange(JSON.stringify(this.currentValue));
  }

  writeValue(obj: any): void {
    const instance = this.getJQueryInstance();

    try {
      this.currentValue = JSON.parse(obj);
      if (this.currentValue == null) {
        this.currentValue = {};
      }
    } catch (e) {
      this.currentValue = {};
      this.supportedLocales.forEach((locale) => {
        this.currentValue[locale.value] = '';
      });
      this.currentValue[this.currentLocale.value] = obj;
      if (this.onChange) {
        this.onChange(JSON.stringify(this.currentValue));
      }
    }

    if (instance) {
      instance?.summernote('code', this.getLocaleModel());
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.disabled) {
      this.getJQueryInstance()?.summernote('disable');
    } else {
      this.getJQueryInstance()?.summernote('enable');
    }
  }

  private init$(): Promise<void> {
    if (!(<any>$.fn).summernote) {
      const summernoteScript: HTMLScriptElement =
        this.renderer2.createElement('script');

      return new Promise<void>((res) => {
        summernoteScript.type = 'text/javascript';
        summernoteScript.src = 'summernote.js';
        summernoteScript.onload = () => {
          this.zone.run(() => {
            // summernote 스크립트 삭제
            this.renderer2.removeChild(
              document.getElementsByTagName('head')[0],
              summernoteScript
            );
            // 임시로 window에 정의한 JQuery 삭제
            delete (window as any).jQuery;
          });
          this.initLang$().then(() => {
            this.zone.run(() => {
              res(null);
            });
          });
        };

        // summernote 설정을 위해 JQuery를 임시로 window에 정의
        Object.assign(window, { jQuery: $ });

        this.renderer2.appendChild(
          document.getElementsByTagName('head')[0],
          summernoteScript
        );
      });
    }
    return this.initLang$();
  }

  private initLang$(): Promise<void> {
    const lang = this.getBrowserCultureLang();

    if (!PwLocaleSummernoteComponent.loadedLangs.has(lang)) {
      const i18nScript: HTMLScriptElement =
        this.renderer2.createElement('script');

      const promise = new Promise<void>((res) => {
        i18nScript.type = 'text/javascript';
        i18nScript.src = `summernote-${lang}.js`;
        i18nScript.onload = () => {
          this.zone.run(() => {
            // summernote 스크립트 삭제
            this.renderer2.removeChild(
              document.getElementsByTagName('head')[0],
              i18nScript
            );
            // 임시로 window에 정의한 JQuery 삭제
            delete (window as any).jQuery;
            res(null);
          });
        };

        // summernote 설정을 위해 JQuery를 임시로 window에 정의
        Object.assign(window, { jQuery: $ });

        this.renderer2.appendChild(
          document.getElementsByTagName('head')[0],
          i18nScript
        );
      });
      PwLocaleSummernoteComponent.loadedLangs.set(lang, promise);
      return promise;
    }
    return PwLocaleSummernoteComponent.loadedLangs.get(lang);
  }

  private getJQueryInstance(): JQuerySummernote {
    const instance = <JQuerySummernote>$(this.container?.nativeElement);
    return instance?.summernote ? instance : null;
  }

  /**
   * 에디터 파일 업로드
   */
  private addImageBlob(blob: Blob | File): Promise<string> {
    return new Promise<string>((res) => {
      if (!this.uploadUrl) {
        // 이미지 파일서버 경로 설정하지 않았을 때. 파일을 base64로 본문에 직접 삽입한다
        // 운영환경에서도 작동하긴 하나, DB 부하와 네트워크 최적화를 위해 개발환경에서만 사용한다
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = () => {
          if (this.maxImgWidth) {
            this.imageService
              .resizeImageSmall(reader.result, this.maxImgWidth)
              .then((img) => {
                res(img);
              });
          } else {
            res(reader.result as string);
          }
        };
      } else {
        this.uploadImage$(blob).subscribe((res1) => {
          res(res1.url);
        });
      }
    });
  }

  /**
   * 이미지 파일 서버 전송 로직
   */
  private uploadImage$(file: Blob | File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, (file as any).name);
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.http
      .post<any>(this.uploadUrl, formData, { headers })
      .pipe(map((res: { url: string }) => res));
  }

  private getBrowserCultureLang() {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return undefined;
    }
    let browserCultureLang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    browserCultureLang = browserCultureLang || window.navigator.language;
    return browserCultureLang;
  }
}
