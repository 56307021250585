import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { JoyfoodLayoutComponent } from './components/joyfood-layout/joyfood-layout.component';
import { RouteGuard } from './core/route.guard';

const routes: Routes = [
  {
    path: '',
    component: JoyfoodLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'ordr',
        loadChildren: () =>
          import('./components/ordr-list/ordr-list.module').then(
            (m) => m.OrdrListModule
          ),
        data: { type: 'ordr' },
      },
      {
        path: 'deliv',
        loadChildren: () =>
          import('./components/ordr-list/ordr-list.module').then(
            (m) => m.OrdrListModule
          ),
        data: { type: 'deliv' },
      },
      {
        path: 'sell/cart',
        loadChildren: () =>
          import('./components/cart/cart.module').then((m) => m.CartModule),
        data: { type: 'sell' },
      },
      {
        path: 'sell/payment',
        loadChildren: () =>
          import('./components/payment/payment.module').then(
            (m) => m.PaymentModule
          ),
        data: { type: 'sell' },
      },
      {
        path: 'delng',
        loadChildren: () =>
          import('./components/delng-list/delng-list.module').then(
            (m) => m.DelngListModule
          ),
      },
      // {
      //   path: 'bbs',
      //   loadChildren: () =>
      //     import('./components/bbs/bbs.module').then((m) => m.BbsModule),
      // },
      // {
      //   path: 'bbs/ntc/form',
      //   loadChildren: () =>
      //     import('./components/ntc-form/ntc-form.module').then(
      //       (m) => m.NtcFormModule
      //     ),
      // },
      // {
      //   path: 'bbs/ntc',
      //   loadChildren: () =>
      //     import('./components/ntc-detail/ntc-detail.module').then(
      //       (m) => m.NtcDetailModule
      //     ),
      // },
      {
        path: 'settings',
        loadChildren: () =>
          import('./components/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'goods',
        loadChildren: () =>
          import('./components/goods-list/goods-list.module').then(
            (m) => m.GoodsListModule
          ),
      },
      {
        path: 'deliv/goods',
        loadChildren: () =>
          import('./components/goods-list/goods-list.module').then(
            (m) => m.GoodsListModule
          ),
        data: { type: 'deliv' },
      },
      {
        path: 'stock/goods',
        loadChildren: () =>
          import('./components/goods-stock-list/goods-stock-list.module').then(
            (m) => m.GoodsStockListModule
          ),
        // data: { type: 'stock' },
      },
      {
        path: 'calc',
        loadChildren: () =>
          import('./components/calc/calc.module').then((m) => m.CalcModule),
      },
      {
        path: 'calc/list',
        loadChildren: () =>
          import('./components/calc-list/calc-list.module').then(
            (m) => m.CalcListModule
          ),
      },
      {
        path: 'calc/form',
        loadChildren: () =>
          import('./components/calc-form/calc-form.module').then(
            (m) => m.CalcFormModule
          ),
      },
      {
        path: 'calc/:id',
        loadChildren: () =>
          import('./components/calc-detail/calc-detail.module').then(
            (m) => m.CalcDetailModule
          ),
      },
      {
        path: 'stats',
        loadChildren: () =>
          import('./components/stats/stats.module').then((m) => m.StatsModule),
      },
      {
        path: 'stats/daily',
        loadChildren: () =>
          import('./components/daily-stats/daily-stats.module').then(
            (m) => m.DailyStatsModule
          ),
      },
      {
        path: 'stats/daily/:id',
        loadChildren: () =>
          import(
            './components/daily-stats-detail/daily-stats-detail.module'
          ).then((m) => m.DailyStatsDetailModule),
      },
      {
        path: 'stats/goods',
        loadChildren: () =>
          import('./components/goods-stats/goods-stats.module').then(
            (m) => m.GoodsStatsModule
          ),
      },
      {
        path: 'cash',
        loadChildren: () =>
          import('./components/cash-list/cash-list.module').then(
            (m) => m.CashListModule
          ),
      },
      {
        path: 'test-print',
        loadChildren: () =>
          import('./components/test-print/test-print.module').then(
            (m) => m.TestPrintModule
          ),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  { path: 'logout', canActivate: [RouteGuard], children: [] },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
