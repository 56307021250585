/* eslint-disable camelcase */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthService, LoginInfo, StorageService } from 'pw-lib';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../components/dialog/dialog.service';
import { subscriptionMap } from '../core/services/rx-stomp.service';

/**
 * 인증(토큰) 정보 취급 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractAuthService {
  protected override extFixedParams: any = {};

  logoutSubject = new Subject();

  constructor(
    protected httpClient: HttpClient,
    protected storageService: StorageService,
    private router: Router,
    private dialogService: DialogService
  ) {
    super(
      httpClient,
      storageService,
      `${environment.apiServerUrl}/oauth/token`,
      'pos',
      'asoyamanami:paywith1234'
    );
  }

  /**
   * 로그인 정보 취득v
   */
  getLoginInfo$(id?: string): Observable<LoginInfo> {
    if (this.loginInfo) {
      // 클래스 내의 loginInfo 값이 있으면, info 호출
      return of(this.loginInfo);
    }

    // 인증정보 획득
    return this.getNewAuth().pipe(
      mergeMap(() => {
        // 로그인 정보 획득
        return this.httpClient.get(`${environment.apiServerUrl}/api/account`);
      }),
      map((account: any) => {
        if (!account?.mrhstTrmnl) {
          throw new Error('Check ID or Password please.');
        }

        this.loginInfo = { ...account?.mrhstTrmnl, userPw: undefined };

        // 로그인 정보 반환
        return this.loginInfo;
      }),
      catchError((e) => {
        console.log(e);

        return throwError(() => e);
      })
    );
  }

  /**
   * 로그아웃
   */
  override logout(reason?: string): void {
    if (reason === 'invalid_token') {
      this.dialogService.alert('MSG.authError', 'error');
    }

    subscriptionMap.forEach((subscription) => {
      subscription?.unsubscribe();
    });

    this.logoutSubject.next(true);
    this.clearAuth();
    this.router.navigateByUrl('/login');
  }
}
