import { Directive } from '@angular/core';
import { GridColumnsDirective } from '@angular/flex-layout';

const inputs = [
  'gdColumns.landscape',
  'gdColumns.portrait',

  'gdColumns.handset',
  'gdColumns.handset.landscape',
  'gdColumns.handset.portrait',

  'gdColumns.tablet',
  'gdColumns.tablet.landscape',
  'gdColumns.tablet.portrait',

  'gdColumns.web',
  'gdColumns.web.landscape',
  'gdColumns.web.portrait',
];

const selector = `[gdColumns.landscape], [gdColumns.portrait],
[gdColumns.handset] [gdColumns.handset.landscape], [gdColumns.handset.portrait],
[gdColumns.tablet], [gdColumns.tablet.landscape], [gdColumns.tablet.portrait],
[gdColumns.web], [gdColumns.web.landscape], [gdColumns.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationGridColumnsDirective extends GridColumnsDirective {
  protected inputs = inputs;
}
