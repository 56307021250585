<div class="more" *ngIf="page$ | async as page">
  <button
    mat-flat-button
    color="accent"
    *ngIf="page.number < page.totalPages - 1"
    (click)="onClick(getNextPageRequest(page))"
  >
    {{ 'more' | translate }} {{ page.number + 1 }} / {{ page.totalPages }}
  </button>
</div>
