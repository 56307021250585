import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';

/**
 * 사전 설정 및 스타일 적용 된 MatTabGroup
 */
@Component({
  selector: 'app-joyfood-tabs',
  templateUrl: './joyfood-tabs.component.html',
  styleUrls: ['./joyfood-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JoyfoodTabsComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ContentChildren(MatTab, { descendants: true }) allTabs: QueryList<MatTab>;

  @Input() stretchTabs = true;

  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent> =
    new EventEmitter();

  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();

  constructor(public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    // 초기화 이후 *ngFor 등으로 동적 추가된 탭 대응
    this.allTabs.changes.subscribe((tabs: QueryList<MatTab>) => {
      // eslint-disable-next-line no-underscore-dangle
      this.tabGroup._allTabs.reset(tabs.toArray());
      this.tabGroup.ngAfterContentInit();
      // 기존에 정적 탭이 있을 경우를 대비해 index 재설정
      // 최초 선택된 탭이 시작이 아닌 끝으로 이동하는 현상 방지
      this.tabGroup.selectedIndex = 0;
    });
  }

  ngAfterViewInit(): void {
    // MatTab 수동 주입 후 Lifecycle 직접 호출
    // eslint-disable-next-line no-underscore-dangle
    this.tabGroup._allTabs.reset(this.allTabs.toArray());
    this.tabGroup.ngAfterContentInit();
    this.tabGroup.selectedIndex =
      this.activatedRoute.snapshot.queryParams?.tabIdx ?? 0;
  }
}
