import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [AlertComponent, ConfirmComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [AlertComponent, ConfirmComponent],
})
export class DialogModule {}
