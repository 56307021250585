import {
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractAuthService } from 'pw-lib';
import {
  Observable,
  catchError,
  finalize,
  mergeMap,
  of,
  retry,
  shareReplay,
  throwError,
} from 'rxjs';

type HttpUpdate = {
  headers?: HttpHeaders;
  context?: HttpContext;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  body?: any | null;
  method?: string;
  url?: string;
  setHeaders?: {
    [name: string]: string | string[];
  };
  setParams?: {
    [param: string]: string;
  };
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private httpRequestUpdate: (token: any) => HttpUpdate = (token) => {
    return {
      setHeaders: {
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
  };

  private authRequest$: Observable<any>;

  constructor(
    private authService: AbstractAuthService,
    private authCheckUrl: string,
    injectedHttpRequestUpdate?: (token: any) => HttpUpdate
  ) {
    this.httpRequestUpdate =
      injectedHttpRequestUpdate || this.httpRequestUpdate;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 토큰 요청 url이 포함되지 않도록 주의
    if (request.url.indexOf(this.authCheckUrl) !== 0) {
      // 주입받은 url로 시작하지 않으면
      return next.handle(request);
    }

    const auth$ = this.authService.auth
      ? of(this.authService.auth)
      : this.authService.getNewAuth();

    return auth$.pipe(
      mergeMap((token) => {
        return (
          token
            ? this.getRequestWithToken(request, next, token)
            : next.handle(request)
        ).pipe(
          retry({
            count: 1,
            delay: (e) => {
              if (e.status >= 400 && e.status <= 499) {
                return token
                  ? this.getRequestWithToken(request, next, token)
                  : next.handle(request);
              }
              return throwError(() => e);
            },
          })
        );
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.error === 'invalid_token') {
          return this.getRefreshRequest(request, next);
        }

        return throwError(() => httpErrorResponse);
      }),
      shareReplay()
    );
  }

  private getRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: any
  ): Observable<HttpEvent<any>> {
    const authAddedRequest: HttpRequest<any> = request.clone(
      this.httpRequestUpdate(token)
    );

    return next.handle(authAddedRequest);
  }

  private getRefreshRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 기존 재인증 요청이 있다면 그대로 사용, 아니면 요청 생성
    this.authRequest$ =
      this.authRequest$ ||
      this.authService.getRefreshAuth().pipe(shareReplay());
    return this.authRequest$.pipe(
      mergeMap((token) => {
        return this.getRequestWithToken(request, next, token);
      }),
      catchError((refreshError: HttpErrorResponse) => {
        if (refreshError.error?.error === 'invalid_token') {
          this.authService.clearAuth();
        }

        return throwError(() => refreshError);
      }),
      finalize(() => {
        this.authRequest$ = null;
      }),
      shareReplay()
    );
  }
}
