import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputToggleComponent } from './input-toggle/input-toggle.component';

const components = [
  InputCheckboxComponent,
  InputRadioComponent,
  InputTextComponent,
  InputToggleComponent,
];

const moduleList = [FormsModule, ReactiveFormsModule, NgxMaskModule];

@NgModule({
  declarations: components,
  imports: [CommonModule, ...moduleList],
  exports: [...components, ...moduleList],
})
export class InputModule {}
