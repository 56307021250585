import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timestamp',
})
export class TimestampPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    const hour = +split[0];
    const minute = +split[1];
    if (hour > 0) {
      if (minute > 0) {
        return this.translateService.instant('timestamp', {
          hour,
          minute,
        });
      }
      return `${hour}${this.translateService.instant('hour')}`;
    }
    return `${minute}${this.translateService.instant('minute')}`;
  }
}
