import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtPageRepositoryService } from '../abstract-ext-repository.service';
import { IDelng } from './delng.model';

@Injectable({
  providedIn: 'root',
})
export class DelngPendingService extends ExtPageRepositoryService<IDelng> {
  baseUri = `api/delng`;

  extFixedParams = { cancelYn: false, acceptYn: false, sort: 'delngDttm,asc' };

  constructor(protected http: HttpClient) {
    super(http);
  }
}
