import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';
import { map, Observable } from 'rxjs';
import { ImageService } from '../../services/image.service';
import { JQuerySummernote } from './summernote.interface';

export interface PwEditorOption {
  defaultFileServerUrl?: string;
}

export const PW_EDITOR_OPTION = new InjectionToken<PwEditorOption>(
  'Paywith content editor option'
);

/**
 * Summernote 컴포넌트
 *
 * @privateRemarks
 * JQuery에 summernote를 추가하기 위해 angular.json 에서
 * projects._<프론트 프로젝트명>_.archtect.build.options 에
 * 다음 항목을 추가해야함:
 *
 * ```json
 *"scripts": [
 *  {
 *    "input": "node_modules/summernote/dist/summernote-lite.js",
 *    "inject": false,
 *    "bundleName": "summernote"
 *  },
 *  {
 *    "input": "node_modules/summernote/dist/lang/summernote-ko-KR.min.js",
 *    "inject": false,
 *    "bundleName": "summernote-ko-KR"
 *  },
 *  {
 *    "input": "node_modules/summernote/dist/lang/summernote-ja-JP.min.js",
 *    "inject": false,
 *    "bundleName": "summernote-ja-JP"
 *  }
 *]
 * ```
 */
@Component({
  selector: 'pw-summernote',
  templateUrl: './pw-summernote.component.html',
  styleUrls: ['./pw-summernote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => PwSummernoteComponent),
    },
  ],
})
export class PwSummernoteComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('summernote') container: ElementRef<HTMLElement>;

  /**
   * 파일 업로드 경로. 서버에 file 키로 요청을 하면, 응답의 url키에 파일 경로가 있어야 한다
   */
  @Input() uploadUrl: string;

  /**
   * 첨부 이미지 최대 폭(px). 지정하면 이 폭 초과하는 이미지를 첨부할 때 이 폭으로 리사이징 한다
   */
  @Input() maxImgWidth: number;

  private value: string;

  private disabled = false;

  private onChange: (value: any) => void;

  private onTouched: () => void;

  // 초기화 진행 작업
  private static init: Promise<void>;

  // 로드할 언어 + 로딩 중 작업
  private static loadedLangs: Map<string, Promise<void>> = new Map();

  private currentInit$: Promise<void>;

  constructor(
    @Optional()
    @Inject(PW_EDITOR_OPTION)
    private pwEditorOption: PwEditorOption,
    private zone: NgZone,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private http: HttpClient,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.uploadUrl =
      this.uploadUrl || this.pwEditorOption?.defaultFileServerUrl;

    this.currentInit$ = this.init$();
  }

  ngAfterViewInit(): void {
    this.currentInit$.then(() => {
      this.getJQueryInstance().summernote({
        toolbar: [
          // ['style', ['style']],
          [
            'font',
            [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'superscript',
              'subscript',
              'clear',
            ],
          ],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph', 'height']],
          ['table', ['table']],
          ['hr', ['hr']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['codeview', 'help']],
        ],
        disableDragAndDrop: true,
        dialogsInBody: true,
        lang: this.getBrowserCultureLang(),
        callbacks: {
          onChange: (contents, instance: JQuerySummernote) => {
            this.onChange?.(contents);
          },
          onFocus: () => {
            this.onTouched?.();
          },
          onImageUpload: (files: FileList) => {
            Array.from(files).forEach((file) => {
              this.addImageBlob(file).then((url) => {
                this.getJQueryInstance().summernote('insertImage', url);
              });
            });
          },
        },
      });
      if (this.value) {
        this.writeValue(this.value);
      }
      this.setDisabledState(this.disabled);
    });
  }

  ngOnDestroy(): void {
    this.getJQueryInstance()?.summernote('destroy');
  }

  writeValue(obj: any): void {
    const instance = this.getJQueryInstance();
    this.value = obj;
    if (instance) {
      instance?.summernote('code', obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.disabled) {
      this.getJQueryInstance()?.summernote('disable');
    } else {
      this.getJQueryInstance()?.summernote('enable');
    }
  }

  private init$(): Promise<void> {
    if (!(<any>$.fn).summernote) {
      // 이미 초기화 진행 중이거나 됐다면, 기존 초기화 작업을 반환
      if (PwSummernoteComponent.init) {
        return PwSummernoteComponent.init;
      }
      const summernoteScript: HTMLScriptElement =
        this.renderer2.createElement('script');

      PwSummernoteComponent.init = new Promise<void>((res) => {
        summernoteScript.type = 'text/javascript';
        summernoteScript.src = 'summernote.js';
        summernoteScript.onload = () => {
          this.zone.run(() => {
            // summernote 스크립트 삭제
            this.renderer2.removeChild(
              document.getElementsByTagName('head')[0],
              summernoteScript
            );
            // 임시로 window에 정의한 JQuery 삭제
            delete (window as any).jQuery;
          });
          this.initLang$().then(() => {
            this.zone.run(() => {
              res(null);
            });
          });
        };

        // summernote 설정을 위해 JQuery를 임시로 window에 정의
        Object.assign(window, { jQuery: $ });

        this.renderer2.appendChild(
          document.getElementsByTagName('head')[0],
          summernoteScript
        );
      });
      return PwSummernoteComponent.init;
    }
    return this.initLang$();
  }

  private initLang$(): Promise<void> {
    const lang = this.getBrowserCultureLang();

    if (!PwSummernoteComponent.loadedLangs.has(lang)) {
      const i18nScript: HTMLScriptElement =
        this.renderer2.createElement('script');

      const promise = new Promise<void>((res) => {
        i18nScript.type = 'text/javascript';
        i18nScript.src = `summernote-${lang}.js`;
        i18nScript.onload = () => {
          this.zone.run(() => {
            // summernote 스크립트 삭제
            this.renderer2.removeChild(
              document.getElementsByTagName('head')[0],
              i18nScript
            );
            // 임시로 window에 정의한 JQuery 삭제
            delete (window as any).jQuery;
            res(null);
          });
        };

        // summernote 설정을 위해 JQuery를 임시로 window에 정의
        Object.assign(window, { jQuery: $ });

        this.renderer2.appendChild(
          document.getElementsByTagName('head')[0],
          i18nScript
        );
      });
      PwSummernoteComponent.loadedLangs.set(lang, promise);
      return promise;
    }
    return PwSummernoteComponent.loadedLangs.get(lang);
  }

  private getJQueryInstance(): JQuerySummernote {
    const instance = <JQuerySummernote>$(this.container?.nativeElement);
    return instance?.summernote ? instance : null;
  }

  /**
   * 에디터 파일 업로드
   */
  private addImageBlob(blob: Blob | File): Promise<string> {
    return new Promise<string>((res) => {
      if (!this.uploadUrl) {
        // 이미지 파일서버 경로 설정하지 않았을 때. 파일을 base64로 본문에 직접 삽입한다
        // 운영환경에서도 작동하긴 하나, DB 부하와 네트워크 최적화를 위해 개발환경에서만 사용한다
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = () => {
          if (this.maxImgWidth) {
            this.imageService
              .resizeImageSmall(reader.result, this.maxImgWidth)
              .then((img) => {
                res(img);
              });
          } else {
            res(reader.result as string);
          }
        };
      } else {
        this.uploadImage$(blob).subscribe((res1) => {
          res(res1.url);
        });
      }
    });
  }

  /**
   * 이미지 파일 서버 전송 로직
   */
  private uploadImage$(file: Blob | File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, (file as any).name);
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.http
      .post<any>(this.uploadUrl, formData, { headers })
      .pipe(map((res: { url: string }) => res));
  }

  private getBrowserCultureLang() {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return undefined;
    }
    let browserCultureLang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    browserCultureLang = browserCultureLang || window.navigator.language;
    return browserCultureLang;
  }
}
