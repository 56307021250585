/* eslint-disable no-console */
import {
  ApplicationRef,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { concat, first, interval, mergeMap, Observable, of, tap } from 'rxjs';

/**
 * 앱 업데이트 옵션
 */
export interface PwUpdateOption {
  /**
   * 업데이트 확인 메시지 텍스트 번역 키
   */
  messageTranslateKey?: string;

  /**
   * 업데이트 확인 버튼 텍스트 번역 키
   */
  actionTranslateKey?: string;

  /**
   * 업데이트 확인 주기(밀리초)
   * @default 10000ms
   */
  updateCheckInterval?: number;

  /**
   * 업데이트 확인 snackBar 가로 위치
   */
  horizontalPosition?: MatSnackBarHorizontalPosition;

  /**
   * 업데이트 확인 snackBar 세로 위치
   */
  verticalPosition?: MatSnackBarVerticalPosition;

  /**
   * true: 업데이트 여부 묻지 않고 새로고침
   * @default false
   */
  forceUpdate?: boolean;
}

/**
 * 앱 업데이트 옵션 인젝션 토큰
 */
export const PW_UPDATE_OPTION = new InjectionToken<PwUpdateOption>(
  '업데이트 확인 메시지 내용, 버튼 텍스트'
);

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private messageTranslateKey: string;

  private actionTranslateKey: string;

  private updateCheckInterval: number;

  private horizontalPosition: MatSnackBarHorizontalPosition;

  private verticalPosition: MatSnackBarVerticalPosition;

  private forceUpdate: boolean;

  constructor(
    @Optional()
    @Inject(PW_UPDATE_OPTION)
    pwUpdateOption: PwUpdateOption,
    private swUpdate: SwUpdate,
    private applicationRef: ApplicationRef,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar
  ) {
    this.messageTranslateKey = pwUpdateOption?.messageTranslateKey;
    this.actionTranslateKey = pwUpdateOption?.actionTranslateKey;
    this.updateCheckInterval =
      !pwUpdateOption?.updateCheckInterval ||
      pwUpdateOption?.updateCheckInterval < 1
        ? 30 * 1000
        : pwUpdateOption.updateCheckInterval;
    this.horizontalPosition = pwUpdateOption?.horizontalPosition || 'right';
    this.verticalPosition = pwUpdateOption?.verticalPosition || 'top';
    this.forceUpdate = pwUpdateOption?.forceUpdate || false;
  }

  /**
   * 새 버전이 있다면 업데이트
   */
  init(): void {
    if (!('serviceWorker' in navigator)) {
      // 서비스워커 없으면 종료
      console.log('service worker is not ready');
      return;
    }

    if (!this.swUpdate.isEnabled) {
      // 서비스워커 사용하지 않으면 종료
      console.log('service worker is not enable');
      return;
    }

    concat(
      // 앱 상태 stable일때
      this.applicationRef.isStable.pipe(first((isStable) => isStable === true)),
      // 매 주기마다
      interval(this.updateCheckInterval)
    )
      // 업데이트 확인
      .subscribe(() => this.swUpdate.checkForUpdate().then());

    // 업데이트 가능하면
    this.swUpdate.available
      .pipe(
        // 새로고침 여부 묻고
        mergeMap(() => (this.forceUpdate ? of(true) : this.askUpdate$())),
        // 업데이트
        mergeMap(() => this.swUpdate.activateUpdate()),
        tap(() => document.location.reload())
      )
      .subscribe();

    // 이용 불가능한 버전이면
    this.swUpdate.unrecoverable
      .pipe(
        // 업데이트
        mergeMap(() => this.swUpdate.activateUpdate()),
        tap(() => document.location.reload())
      )
      .subscribe();
  }

  /**
   * 업데이트 진행 여부 확인
   */
  askUpdate$(): Observable<void> {
    const message = this.messageTranslateKey
      ? this.translateService.instant(this.messageTranslateKey)
      : 'New version available';
    const action = this.actionTranslateKey
      ? this.translateService.instant(this.actionTranslateKey)
      : 'Update';

    return this.matSnackBar
      .open(message, action, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
      .onAction();
  }
}
