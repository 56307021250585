import * as $ from 'jquery';
import {
  JQuerySummernote,
  PluginContext,
} from '../../../components/pw-summernote/summernote.interface';

export class LocalePlugin {
  static currentLocale: { name: string; value: string };

  static supportedLocales: { name: string; value: string }[];

  private containerQuery: JQuerySummernote;

  constructor(private context: PluginContext) {
    context.memo('button.locale', () => {
      return context.ui
        .buttonGroup(
          [
            context.ui.button({
              codeviewKeepButton: true,
              className: 'dropdown-toggle',
              contents: context.ui.dropdownButtonContents(
                '<span class="note-locale"></span>',
                context.options
              ),
              data: {
                toggle: 'dropdown',
              },
            }),
            context.ui.dropdownCheck({
              codeviewKeepButton: true,
              className: 'dropdown-locale',
              checkClassName: context.options.icons.menuCheck,
              items: LocalePlugin.supportedLocales.map((locale) => {
                return {
                  ...locale,
                  toString: () => locale.name,
                };
              }),
              template: (item) => {
                return item.name;
              },
              itemClick: (
                e: JQuery.Event,
                locale: { name: string; value: string },
                value: string
              ) => {
                e.preventDefault();
                context.$note.trigger('localeChanged', locale);
                this.containerQuery
                  ?.find('.dropdown-locale a')
                  ?.each((idx, item) => {
                    const $item = $(item);
                    // always compare with string to avoid creating another func.
                    const isChecked = `${$item.data('value')}` === value;
                    $item.toggleClass('checked', isChecked);
                  });
                this.containerQuery?.find('.note-locale')?.text(locale.name);
              },
            }),
          ],
          {
            callback: (container: JQuerySummernote) => {
              const locale =
                LocalePlugin.currentLocale ?? LocalePlugin.supportedLocales[0];
              this.containerQuery = container;
              // Locale 변경 버튼은 상시 활성
              new MutationObserver((record) => {
                if (
                  !record[0].oldValue.includes('disabled') &&
                  (<HTMLButtonElement>record[0].target).className.includes(
                    'disabled'
                  )
                ) {
                  const query = $(record[0].target);
                  query.toggleClass('disabled', false);
                  query.attr('disabled', null);
                }
              }).observe(this.containerQuery.find('button').get(0), {
                attributes: true,
                attributeFilter: ['class'],
                attributeOldValue: true,
              });
              this.containerQuery?.find('.note-locale')?.text(locale.name);
              this.containerQuery
                ?.find('.dropdown-locale a')
                ?.each((idx, item) => {
                  const $item = $(item);
                  // always compare with string to avoid creating another func.
                  const isChecked = `${$item.data('value')}` === locale.value;
                  $item.toggleClass('checked', isChecked);
                });
            },
          }
        )
        .render();
    });
  }
}
