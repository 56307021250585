/**
 * 각종 유용한 기능들 모음
 */
export class Utils {
  /**
   * 문자인증에 사용되는 uuid
   */
  static getUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // y의 자리에는 8, 9, a, b 가능
      const v = c === 'x' ? Math.random() * 16 : Math.random() * 4 + 8;
      return Math.floor(v).toString(16);
    });
  }

  /**
   * 플러터 웹앱 여부
   */
  static isApp(): boolean {
    return window.navigator.userAgent.indexOf('paywith-flutter-app') >= 0;
  }

  /**
   * 플러터 웹앱 안드로이드 여부
   */
  static isAndroidApp(): boolean {
    return window.navigator.userAgent.indexOf('Android;') >= 0;
  }

  /**
   * 플러터 웹앱 ios 여부
   */
  static isIosApp(): boolean {
    return window.navigator.userAgent.indexOf('iPhone;') >= 0;
  }

  /**
   * 플러터 웹앱 안드로이드, ios 이외 여부
   */
  static isElseApp(): boolean {
    return this.isApp() && !this.isAndroidApp() && !this.isIosApp();
  }

  /**
   * 모바일(웹/앱) 여부를 화면 크기로 판단
   */
  staticaisMobileSize(): boolean {
    return window.innerWidth <= 768;
  }

  /**
   * 분 단위 시간을 LocalTime로 변환
   */
  static toNumberToLocalTime(time: number): string {
    const hour = Math.floor(+time / 60);
    const min = +time % 60;
    return `${hour.toString().padStart(2, '0')}:${min
      .toString()
      .padStart(2, '0')}:00`;
  }

  /**
   * LocalTime을 분 단위 시간으로 변환
   */
  static toLocalTimeToNumber(localTime: string): number {
    if (!localTime) {
      return 0;
    }
    const split = localTime.split(':');
    const hour = +split[0];
    const minute = +split[1];
    const time = hour * 60 + minute;
    return !Number.isNaN(time) ? time : 0;
  }
}
