import { Directive } from '@angular/core';
import { ClassDirective } from '@angular/flex-layout';

const inputs = [
  'ngClass.landscape',
  'ngClass.portrait',

  'ngClass.handset',
  'ngClass.handset.landscape',
  'ngClass.handset.portrait',

  'ngClass.tablet',
  'ngClass.tablet.landscape',
  'ngClass.tablet.portrait',

  'ngClass.web',
  'ngClass.web.landscape',
  'ngClass.web.portrait',
];

const selector = `[ngClass.landscape], [ngClass.portrait],
[ngClass.handset], [ngClass.handset.landscape], [ngClass.handset.portrait],
[ngClass.tablet], [ngClass.tablet.landscape], [ngClass.tablet.portrait],
[ngClass.web], [ngClass.web.landscape], [ngClass.web.portrait]`;

@Directive({ selector, inputs })
export class OrientationClassDirective extends ClassDirective {
  protected inputs = inputs;
}
