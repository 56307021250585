import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRepositoryService } from '../abstract-repository.service';
import { IGoodsGrp } from './goods-grp.model';

@Injectable({
  providedIn: 'root',
})
export class GoodsGrpService extends PageRepositoryService<IGoodsGrp> {
  baseUri = `api/goodsGrp`;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }
}
