import { Component, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { IMrhstTrmnl } from '../../repository/mrhst-trmnl/mrhst-trmnl.model';
import { PosService } from '../../services/pos.service';

@Component({
  selector: 'app-joyfood-menu',
  templateUrl: './joyfood-menu.component.html',
  styleUrls: ['./joyfood-menu.component.scss'],
})
export class JoyfoodMenuComponent implements OnInit {
  // @Input() type: 'old' | 'new' = 'new';

  @Input() hasBack = true;

  mrhstTrmnl: IMrhstTrmnl;

  constructor(
    private posService: PosService,
    @Optional() private matDialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.posService
      .getMrhstTrmnl$()
      .pipe(
        tap((mrhstTrmnl) => {
          this.mrhstTrmnl = mrhstTrmnl;
        })
      )
      .subscribe();
  }

  onClickBack(): void {
    if (this.matDialogRef) {
      this.matDialogRef.close();
    } else {
      window.history.back();
    }
  }
}
