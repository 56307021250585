<div
  fxLayout
  fxLayoutGap="8px"
  safe-bottom
  safe-side
  class="footer-wrapper"
  ngClass.landscape="landscape"
  [style.height.px]="height"
>
  <ng-content></ng-content>
</div>
<div
  class="footer-spacer"
  ngClass.landscape="landscape"
  [style.padding-top.px]="height"
></div>
