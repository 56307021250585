<mat-tab-group
  #tabGroup
  [attr.mat-stretch-tabs]="stretchTabs ? true : null"
  [disablePagination]="true"
  [animationDuration]="0"
  (selectedTabChange)="selectedTabChange.emit($event)"
  (selectedIndexChange)="selectedIndexChange.emit($event)"
>
  <ng-content></ng-content>
</mat-tab-group>
