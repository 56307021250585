import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutOrientationModule } from '../flex-layout-orientation/flex-layout-orientation.module';
import { JoyfoodMenuComponent } from './joyfood-menu.component';

@NgModule({
  declarations: [JoyfoodMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    FlexLayoutModule,
    FlexLayoutOrientationModule,
    TranslateModule,
  ],
  exports: [JoyfoodMenuComponent],
})
export class JoyfoodMenuModule {}
