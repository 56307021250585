import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-joyfood-header',
  templateUrl: './joyfood-header.component.html',
  styleUrls: ['./joyfood-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JoyfoodHeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('left') left: ElementRef;

  @ViewChild('right') right: ElementRef;

  /**
   * 헤더 높이
   */
  @Input() height = 56;

  /**
   * 뒤로가기 버튼 여부
   */
  @Input() hasBack = true;

  /**
   * 제목 패딩 단위
   */
  @Input() titlePaddingUnit = 40;

  /**
   * 제목 패딩 단계
   */
  titlePaddingLevel: number;

  /**
   * 뒤로가기 클릭시
   */
  @Output() backClick = new EventEmitter();

  /**
   * 왼쪽 아이콘 개수
   */
  leftIconCnt = 0;

  /**
   * 오른쪽 아이콘 개수
   */
  rightIconCnt = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // 왼쪽 아이콘 개수
    this.leftIconCnt =
      (this.left?.nativeElement as HTMLElement)?.children.length || 0;
    // 오른쪽 아이콘 개수
    this.rightIconCnt =
      (this.right?.nativeElement as HTMLElement)?.children.length || 0;

    // 제목 패딩은 좌우 아이콘 개수중 높은쪽에 맞춤
    this.titlePaddingLevel = Math.max(this.leftIconCnt, this.rightIconCnt);

    this.changeDetectorRef.detectChanges();
  }

  onBackClick(): void {
    if (this.backClick.observed) {
      this.backClick.emit();
      return;
    }

    window.history.back();
  }
}
