import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { map, Observable } from 'rxjs';
import { PageRepositoryService } from '../../repository/abstract-repository.service';
import { IPageInfo } from '../../repository/page.model';

@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
})
export class MoreButtonComponent implements OnInit, OnChanges {
  @Input() pageService: PageRepositoryService<any>;

  page$: Observable<IPageInfo>;

  constructor() {}

  ngOnInit(): void {
    if (this.pageService) {
      this.page$ = this.pageService.list$.pipe(map((page) => page.page));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // 서비스 변경되면 page$ 재설정
    if (changes.pageService && this.pageService) {
      this.page$ = this.pageService.list$.pipe(map((page) => page.page));
    }
  }

  getNextPageRequest(pageInfo: IPageInfo): { page: number } {
    return { page: pageInfo.number + 1 };
  }

  onClick(pageReq: { page: number }): void {
    this.pageService.appendPage({
      ...this.pageService.recentSearchQuery,
      ...pageReq,
    });
  }
}
