import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRepositoryService } from '../abstract-repository.service';
import { IMrhstTrmnl } from './mrhst-trmnl.model';

@Injectable({
  providedIn: 'root',
})
export class MrhstTrmnlService extends PageRepositoryService<IMrhstTrmnl> {
  baseUri = `api/mrhstTrmnl`;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }
}
