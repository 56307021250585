import { Directive } from '@angular/core';
import { LayoutDirective } from '@angular/flex-layout';

const inputs = [
  'fxLayout.landscape',
  'fxLayout.portrait',

  'fxLayout.handset',
  'fxLayout.handset.landscape',
  'fxLayout.handset.portrait',

  'fxLayout.tablet',
  'fxLayout.tablet.landscape',
  'fxLayout.tablet.portrait',

  'fxLayout.web',
  'fxLayout.web.landscape',
  'fxLayout.web.portrait',
];

const selector = `[fxLayout.landscape], [fxLayout.portrait],
[fxLayout.handset], [fxLayout.handset.landscape], [fxLayout.handset.portrait],
[fxLayout.tablet], [fxLayout.tablet.landscape], [fxLayout.tablet.portrait]
[fxLayout.web], [fxLayout.web.landscape], [fxLayout.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
