import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

const movingPage = style({
  width: '100vw',
  height: '100vh',
  position: 'absolute',
});

const enterPage = style({
  // left: '0%',
  opacity: 1,
  'z-index': 1,
});

const leavePage = style({
  // left: '100%',
  opacity: 0,
  'z-index': 1,
});

const timing = '300ms ease-in-out';

const animationSpeed = 300;

export const slideAnimation = trigger('routeAnimations', [
  transition('* => toLeft', [
    query(':enter', [movingPage, leavePage, animate(timing, enterPage)], {
      optional: true,
    }),
  ]),
  transition('* => toRight', [
    query(':leave', [movingPage, enterPage, animate(timing, leavePage)], {
      optional: true,
    }),
  ]),
]);

export const fadeAnimation = trigger('routeAnimations', [
  transition('* => forward', [
    query(':leave', [movingPage, leavePage], {
      optional: true,
    }),
    query(
      ':enter',
      [
        movingPage,
        leavePage,
        animate(`${animationSpeed}ms ease-in-out`, enterPage),
      ],
      {
        optional: true,
      }
    ),
  ]),
]);
