import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { Observable, tap } from 'rxjs';
import { GoodsDeliveryService } from 'src/app/repository/goods/goods-delivery.service';
import { GoodsTakeoutService } from 'src/app/repository/goods/goods-takeout.service';
import { IMrhstTrmnl } from 'src/app/repository/mrhst-trmnl/mrhst-trmnl.model';
import { DelngAlarmService } from 'src/app/services/delng-alarm.service';
import { PosService } from 'src/app/services/pos.service';
import { UiService } from '../../core/services/ui.service';
import { GoodsGrpService } from '../../repository/goods-grp/goods-grp.service';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-joyfood-layout',
  templateUrl: './joyfood-layout.component.html',
  styleUrls: ['./joyfood-layout.component.scss'],
})
export class JoyfoodLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('sideNav') sideNavContainer: MatSidenavContainer;

  mrhstTrmnl$: Observable<IMrhstTrmnl>;

  mrhstId: number;

  audio: HTMLAudioElement;

  isAudioPlaying = false;

  constructor(
    public uiService: UiService,
    private posService: PosService,
    private goodsTakeoutService: GoodsTakeoutService,
    private goodsDeliveryService: GoodsDeliveryService,
    private goodsGrpService: GoodsGrpService,
    private dialogService: DialogService,
    private delngAlarmService: DelngAlarmService
  ) {}

  ngOnInit(): void {
    this.mrhstTrmnl$ = this.posService.getMrhstTrmnl$().pipe(
      tap((mrhstTrmnl) => {
        this.mrhstId = mrhstTrmnl.mrhst.id;
        this.delngAlarmService.alarmOn();
      })
    );
  }

  ngAfterViewInit(): void {
    this.uiService.openDrawerEmitter.subscribe(() => {
      this.sideNavContainer.open();
    });
  }
}
