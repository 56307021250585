import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ScrollEvent {
  scrollTop?: number;
  scrollLeft?: number;
  scrollHeight?: number;
  scrollWidth?: number;
}

/**
 * UI 제어를 위한 클래스
 */
@Injectable({
  providedIn: 'root',
})
export class UiService {
  /**
   * app-root의 스크롤 이벤트 서브젝트
   */
  scrollEvent$: BehaviorSubject<ScrollEvent> = new BehaviorSubject({});

  /**
   * 네비게이션 드로어 열기 이벤트
   */
  openDrawerEmitter: EventEmitter<void> = new EventEmitter();

  constructor() {}

  openDrawer(): void {
    this.openDrawerEmitter.emit();
  }
}
