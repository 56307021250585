import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtPageRepositoryService } from '../abstract-ext-repository.service';
import { Goods } from './goods.model';

@Injectable({
  providedIn: 'root',
})
export class GoodsDeliveryService extends ExtPageRepositoryService<Goods> {
  baseUri = `api/goods`;

  extFixedParams = {
    activeFl: true,
    attrJson: 'DELIVERY',
    sort: ['sortNumber,asc', 'regDttm,desc'],
  };

  constructor(protected http: HttpClient) {
    super(http);
  }
}
