<h3>
  {{ matDialogData?.header | translate }}
</h3>

<p [innerHTML]="matDialogData?.body | translate"></p>

<div fxLayoutAlign="end" fxLayoutGap="8px">
  <button mat-flat-button mat-dialog-close>
    {{ matDialogData?.ok || 'ok' | translate }}
  </button>
</div>
