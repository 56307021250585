<!-- <ng-container *ngIf="type === 'old'">
<div fxLayout fxLayoutGap="80px" fxLayoutAlign="center center">
  <span routerLink="/home">{{ 'home' | translate }}</span>
  <span routerLink="/goods">{{ 'sell' | translate }}</span>
  < !-- <span>{{ 'table' | translate }}</span> -- >
  <span routerLink="/ordr">{{ 'ordr' | translate }}</span>
  <span routerLink="/deliv">{{ 'deliv' | translate }}</span>
  <span routerLink="/delng">{{ 'hist' | translate }}</span>
  <span routerLink="/settings">{{ 'settings' | translate }}</span>
</div>
<div fxFlex></div>
<div fxLayout>
  < !-- <button mat-ripple>
    <img class="locale" src="/assets/imgs/ic_locale.svg" />
  </button> -- >
  <button class="old" mat-ripple (click)="onLogoutClick()">
    <img class="logout" src="/assets/imgs/ic_logout.svg" />
  </button>
</div>
</ng-container> -->
<div
  class="header"
  fxHide
  fxShow.landscape
  fxFlex="auto"
  fxLayout
  fxLayoutAlign="space-between center"
  fxLayoutGap="20px"
>
  <button
    class="back"
    [ngClass]="{ hidden: !hasBack }"
    [disabled]="!hasBack"
    mat-icon-button
    left
    (click)="onClickBack()"
  >
    <img src="/assets/imgs/ic_back_landscape.svg" />
  </button>
  <span class="title">
    <ng-content></ng-content>
  </span>
  <div right fxLayout>
    <div
      class="mrhst"
      fxLayout="column"
      fxLayoutAlign="center end"
      *ngIf="mrhstTrmnl"
    >
      <span class="nm">{{ mrhstTrmnl.mrhst.mrhstNm }}</span>
      <span class="info">{{ mrhstTrmnl.trmnlNm }}</span>
    </div>
    <div divider></div>
    <button mat-ripple routerLink="/home">
      <mat-icon class="home">storefront</mat-icon>
    </button>
    <div divider></div>
    <button mat-ripple routerLink="/logout">
      <mat-icon class="logout">logout</mat-icon>
    </button>
  </div>
</div>
