<mat-sidenav-container #sideNav>
  <mat-sidenav position="end" [autoFocus]="false">
    <div
      class="mrhst-info"
      fxLayout="column"
      *ngIf="mrhstTrmnl$ | async as mrhstTrmnl"
    >
      <span class="mrhst-nm"> {{ mrhstTrmnl.mrhst.mrhstNm }} </span>
      <span class="mrhst-cn"> {{ mrhstTrmnl.trmnlNm }} </span>
    </div>
    <button
      mat-flat-button
      class="settings"
      fxLayout
      fxLayoutAlign="end"
      routerLink="settings"
      (click)="sideNav.close()"
    >
      <div fxLayout fxLayoutAlign="center center">
        <img src="/assets/imgs/ic_settings.svg" />
        <span> {{ 'settings' | translate }} </span>
      </div>
    </button>
    <mat-list class="menu-list">
      <mat-list-item mat-ripple routerLink="home" (click)="sideNav.close()">
        <span mat-line>{{ 'home' | translate }}</span>
      </mat-list-item>
      <mat-list-item mat-ripple routerLink="goods" (click)="sideNav.close()">
        <span mat-line>{{ 'sell' | translate }}</span>
      </mat-list-item>
      <!-- <mat-list-item routerLink="." mat-ripple (click)="sideNav.close()">
        <span mat-line> {{ 'table' | translate }} </span>
      </mat-list-item> -->
      <mat-list-item mat-ripple routerLink="ordr" (click)="sideNav.close()">
        <span mat-line>{{ 'ordr' | translate }}</span>
      </mat-list-item>
      <mat-list-item mat-ripple routerLink="deliv" (click)="sideNav.close()">
        <span mat-line>{{ 'deliv' | translate }}</span>
      </mat-list-item>
      <mat-list-item
        mat-ripple
        routerLink="deliv/goods"
        (click)="sideNav.close()"
      >
        <span mat-line>{{ 'goodsInfoForDeliv' | translate }}</span>
      </mat-list-item>
      <mat-list-item
        mat-ripple
        routerLink="stock/goods"
        (click)="sideNav.close()"
      >
        <span mat-line>{{ 'goodsStockSettings' | translate }}</span>
      </mat-list-item>
      <mat-list-item mat-ripple routerLink="delng" (click)="sideNav.close()">
        <span mat-line>{{ 'hist' | translate }}</span>
      </mat-list-item>
    </mat-list>
    <div fxLayout class="menu-list">
      <button mat-icon-button routerLink="/logout">
        <img src="/assets/imgs/ic_logout.svg" />
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<audio src="/assets/delivery.mp3"></audio>
<audio src="/assets/takeout.mp3"></audio>
