import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from './dialog/dialog.module';
import { FlexLayoutOrientationModule } from './flex-layout-orientation/flex-layout-orientation.module';
import { JoyfoodFooterModule } from './joyfood-footer/joyfood-footer.module';
import { JoyfoodHeaderModule } from './joyfood-header/joyfood-header.module';
import { JoyfoodLayoutModule } from './joyfood-layout/joyfood-layout.module';
import { JoyfoodMenuModule } from './joyfood-menu/joyfood-menu.module';
import { JoyfoodTabsModule } from './joyfood-tabs/joyfood-tabs.module';
import { MoreButtonModule } from './more-button/more-button.module';

const modules = [
  CommonModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  FlexLayoutOrientationModule,
  MatIconModule,
  MatButtonModule,
  MatTabsModule,
  MatRippleModule,
  MatDialogModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  TranslateModule,
  DialogModule,
  JoyfoodLayoutModule,
  JoyfoodHeaderModule,
  JoyfoodFooterModule,
  JoyfoodTabsModule,
  JoyfoodMenuModule,
  MoreButtonModule,
];

/**
 * 공통 모듈
 */
@NgModule({
  imports: modules,
  exports: modules,
})
export class SharedModule {}
