import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private httpClient: HttpClient) {}

  getServerTime(): Observable<Date> {
    return this.httpClient.get<Date>(
      `${environment.apiServerUrl}/api/servertime`
    );
  }
}
