import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwSummernoteComponent } from './pw-summernote.component';

@NgModule({
  declarations: [PwSummernoteComponent],
  imports: [CommonModule],
  exports: [PwSummernoteComponent],
})
export class PwSummernoteModule {}
