import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { JoyfoodTabsComponent } from './joyfood-tabs.component';

@NgModule({
  declarations: [JoyfoodTabsComponent],
  imports: [CommonModule, MatTabsModule],
  exports: [JoyfoodTabsComponent],
})
export class JoyfoodTabsModule {}
