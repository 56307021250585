import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutOrientationModule } from '../flex-layout-orientation/flex-layout-orientation.module';
import { JoyfoodHeaderComponent } from './joyfood-header.component';

@NgModule({
  declarations: [JoyfoodHeaderComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    FlexLayoutOrientationModule,
  ],
  exports: [JoyfoodHeaderComponent],
})
export class JoyfoodHeaderModule {}
