import { EventEmitter, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../components/dialog/dialog.service';
import {
  IMrhstTrmnl,
  MrhstTrmnl,
} from '../repository/mrhst-trmnl/mrhst-trmnl.model';
import { MrhstTrmnlService } from '../repository/mrhst-trmnl/mrhst-trmnl.service';

/**
 * 매장 정보 캐싱 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class PosService {
  private mrhstTrmnl: MrhstTrmnl;

  delngAlarmEmitter: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private mrhstTrmnlService: MrhstTrmnlService
  ) {}

  getMrhstTrmnl$(): Observable<IMrhstTrmnl> {
    if (this.mrhstTrmnl) {
      return of(this.mrhstTrmnl);
    }

    return this.authService.getLoginInfo$().pipe(
      tap((mrhstTrmnl) => {
        this.mrhstTrmnl = mrhstTrmnl;
      }),
      catchError((e) => {
        return this.dialogService.error('MSG.mrhstTrmnlError').pipe(
          tap(() => {
            this.authService.logout();
          })
        );
      })
    );
  }

  refreshMrhstTrmnl$(): Observable<IMrhstTrmnl> {
    return this.mrhstTrmnlService
      .findPage({
        userId: this.authService.userName || this.authService.loginInfo.userId,
      })
      .pipe(
        map(({ content }) => {
          [this.mrhstTrmnl] = content;
          return this.mrhstTrmnl;
        })
      );
  }
}
