import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-joyfood-footer',
  templateUrl: './joyfood-footer.component.html',
  styleUrls: ['./joyfood-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JoyfoodFooterComponent implements OnInit {
  @Input() height = 68;

  constructor() {}

  ngOnInit(): void {}
}
