// import { Injectable } from '@angular/core';
// import {
//   ActivatedRouteSnapshot,
//   CanActivate,
//   Router,
//   RouterStateSnapshot,
//   UrlTree,
// } from '@angular/router';
// import {
//   AppService,
//   BridgeService,
//   PushService,
// } from 'projects/pw-lib/src/public-api';
// import { Observable, of } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';
// import { DialogService } from '../components/dialog/dialog.service';
// import { AuthService } from './auth.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGuard implements CanActivate {
//   constructor(
//     private router: Router,
//     private dialogService: DialogService,
//     private pushService: PushService,
//     private bridgeService: BridgeService,
//     private appService: AppService,
//     private authService: AuthService
//   ) {}

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ):
//     | Promise<boolean | UrlTree>
//     | Observable<boolean | UrlTree>
//     | UrlTree
//     | boolean {
//     if (this.authService.loginInfo) {
//       return true;
//     }

//     return this.authService.getNewAuth().pipe(
//       map((token) => {
//         if (token) {
//           return true;
//         }

//         return this.router.parseUrl('/logout');
//       }),
//       catchError(() => {
//         return of(this.router.parseUrl('/logout'));
//       })
//     );
//   }
// }

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AppService,
  BridgeService,
  DeviceService,
  PushService,
} from 'projects/pw-lib/src/public-api';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DialogService } from '../components/dialog/dialog.service';
import { MrhstTrmnl } from '../repository/mrhst-trmnl/mrhst-trmnl.model';
import { UserAppService } from '../repository/user/user-app.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private userAppService: UserAppService,
    private pushService: PushService,
    private bridgeService: BridgeService,
    private appService: AppService,
    private deviceService: DeviceService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree>
    | UrlTree
    | boolean {
    return this.authService.getLoginInfo$().pipe(
      map((loginInfo) => {
        if (loginInfo) {
          this.saveUserApp().subscribe();
          return true;
        }

        return this.router.parseUrl('/logout');
      }),
      catchError(() => {
        return of(this.router.parseUrl('/logout'));
      })
    );
  }

  private saveUserApp(): Observable<MrhstTrmnl> {
    return forkJoin([
      from(this.appService.version),
      from(this.pushService.pushToken),
      from(this.deviceService.udid),
    ]).pipe(
      mergeMap(([version, token, udid]) => {
        const { platform } = this.bridgeService;

        if (!token) {
          return of({});
        }

        return this.userAppService.create({
          appVerNm: version,
          userAppOsType: platform === 'WEB' ? 'ETC' : platform,
          pushKey: token,
          trmnlId: udid,
          mrhstTrmnl: { id: this.authService.loginInfo.id },
        });
      })
    );
  }
}
