import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FlexLayoutOrientationModule } from '../flex-layout-orientation/flex-layout-orientation.module';
import { JoyfoodFooterComponent } from './joyfood-footer.component';

@NgModule({
  declarations: [JoyfoodFooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FlexLayoutOrientationModule,
  ],
  exports: [JoyfoodFooterComponent],
})
export class JoyfoodFooterModule {}
