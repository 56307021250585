<h3>
  {{ matDialogData?.header | translate }}
</h3>

<p [innerHTML]="matDialogData?.body | translate | safeHtml"></p>

<div fxLayoutAlign="end">
  <button mat-flat-button mat-dialog-close="left">
    {{ matDialogData?.left || 'no' | translate }}
  </button>
  <button mat-flat-button mat-dialog-close="right">
    {{ matDialogData?.right || 'ok' | translate }}
  </button>
</div>
