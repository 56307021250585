import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRepositoryService } from '../abstract-repository.service';
import { IUserApp } from './user-app.model';

@Injectable({
  providedIn: 'root',
})
export class UserAppService extends PageRepositoryService<IUserApp> {
  protected baseUri = 'api/userApp';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
