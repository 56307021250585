import { Directive } from '@angular/core';
import { GridRowsDirective } from '@angular/flex-layout';

const inputs = [
  'gdRows.landscape',
  'gdRows.portrait',

  'gdRows.handset',
  'gdRows.handset.landscape',
  'gdRows.handset.portrait',

  'gdRows.tablet',
  'gdRows.tablet.landscape',
  'gdRows.tablet.portrait',

  'gdRows.web',
  'gdRows.web.landscape',
  'gdRows.web.portrait',
];

const selector = `[gdRows.landscape], [gdRows.portrait],
[gdRows.handset], [gdRows.handset.landscape], [gdRows.handset.portrait],
[gdRows.tablet], [gdRows.tablet.landscape], [gdRows.tablet.portrait],
[gdRows.web], [gdRows.web.landscape], [gdRows.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationGridRowsDirective extends GridRowsDirective {
  protected inputs = inputs;
}
