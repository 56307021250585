import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

/**
 * 라우팅 애니메이션 방향 타입
 */
export type RouteAnimationDirection = 'toLeft' | 'toRight' | null;

@Injectable({
  providedIn: 'root',
})
export class PwLayoutService {
  /**
   * pw-content 스크롤 이벤트를 컴포넌트를 사용하지 않는곳에서도 수신
   */
  contentScroll$: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * 애니메이션 방향
   */
  animationDirection$: BehaviorSubject<RouteAnimationDirection> =
    new BehaviorSubject<RouteAnimationDirection>(null);

  /**
   * 각 페이지의 `Root`로부터의 path 리스트
   */
  pathList: string[] = [];

  /**
   * 현재 애니메이션 진행중인 `depth`
   */
  animatingDepth = -1;

  /**
   * 현재(마지막) 페이지의 `depth`
   */
  get currentDepth(): number {
    return this.pathList.length - 1;
  }

  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.animationDirection$.subscribe((direction) => {
      this.animatingDepth = direction ? this.currentDepth : -1;
    });
    this.document.documentElement.style.setProperty(
      '--window-height',
      `${window.innerHeight.toString()}px`
    );
    window.onresize = () => {
      this.document.documentElement.style.setProperty(
        '--window-height',
        `${window.innerHeight.toString()}px`
      );
    };
  }

  /**
   * Root로부터의 경로를 구하는 메소드, 각 페이지별로 자신의 경로를 찾을때 이용
   * @param activatedRoute 확인하려는 `ActivatedRoute`
   */
  getPathFromRoot(activatedRoute: ActivatedRoute): string {
    let path = '';
    const { pathFromRoot } = activatedRoute.snapshot;

    pathFromRoot.forEach((activatedRouteSnapshot) => {
      path = path += `${this.getPath(activatedRouteSnapshot)}`;
    });

    return path;
  }

  /**
   * 특정 `ActivatedRouteSnapshot`의 파라미터 포함한 경로
   */
  getPath(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    let path = '';

    activatedRouteSnapshot.url.forEach((urlSegment) => {
      path += `/${urlSegment.path}`;
      path += urlSegment.parameterMap.keys.reduce((pre, cur) => {
        return `${pre};${cur}=${urlSegment.parameterMap.get(cur)}`;
      }, '');
    });

    return path;
  }
}
