import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { StorageService } from 'projects/pw-lib/src/public-api';
import { EMPTY, catchError, filter, map, mergeMap, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../components/dialog/dialog.service';
import {
  RxStompService,
  subscriptionMap,
} from '../core/services/rx-stomp.service';
import { CommonService } from '../repository/common.service';
import { DelngPendingService } from '../repository/delng/delng-pending.service';
import { IDelng } from '../repository/delng/delng.model';
import { PosService } from './pos.service';

const INTERVAL = 30000;
const ALARM_CHECK_KEY_NAME = 'alarmCheckDttm';
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const ERROR_COUNT_LIMIT = 3;

@Injectable({
  providedIn: 'root',
})
export class DelngAlarmService {
  audio: HTMLAudioElement;

  /**
   * 오류 발생 횟수
   */
  errorCount = 0;

  private interval: any;

  private latestDelngDttm: string;

  private latestDelng: IDelng;

  private audioEndedEvent = () => {
    if (!this.audio) {
      return;
    }

    this.stopSound();
    const shouldPlayAlarm = this.shouldPlayAlarm(this.latestDelng);

    if (shouldPlayAlarm) {
      setTimeout(() => {
        this.playSound(this.latestDelng);
      }, 500);
    }
  };

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService,
    private rxStompService: RxStompService,
    private authService: AuthService,
    private posService: PosService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private delngPendingService: DelngPendingService
  ) {
    this.authService.logoutSubject.subscribe(() => {
      this.alarmOff();
    });
  }

  alarmOn(): void {
    this.clearInterval();
    this.setInterval();
    this.commonService
      .getServerTime()
      .pipe(
        tap((serverTime) => {
          const now = dayjs(serverTime).format(DATE_FORMAT);
          this.latestDelngDttm = now;
          this.storageService.set(ALARM_CHECK_KEY_NAME, now);
        }),
        mergeMap(() => this.posService.getMrhstTrmnl$()),
        map((mrhstTrmnl) => {
          // 유저의 조작이 있기 전까진 audio autoplay 불가하므로 의미 없는 다이얼로그 표시하여 조작 유도
          this.dialogService
            .alert(
              // 1. 미디어 볼륨을 확인 해주세요.
              // 2. 화면이 꺼져있으면 주문 수신할수 없습니다.
              // 매장 : ~
              // 현재 시간 : ~

              `1. メディアボリュームを確認してください。<br/>2. このアプリの画面がオフになっていると注文を受信できません。<br/><br/>店舗名 : ${mrhstTrmnl?.mrhst?.mrhstNm}<br/>現在の日時 : ${this.latestDelngDttm}`,
              '注文アラーム受信'
            )
            .subscribe();

          return mrhstTrmnl.mrhst.id;
        }),
        tap((mrhstId) => this.subscribeWs(mrhstId)),
        catchError((error) => {
          return this.dialogService.error(
            `注文アラームの受信に失敗しました。アプリを再起動してください。<br/>同じ現象が繰り返される場合は、管理者にお問い合わせください。<br/><br/>${error}`
          );
        })
      )
      .subscribe();
  }

  alarmOff(): void {
    this.clearInterval();
    this.audio?.removeEventListener('ended', this.audioEndedEvent);
  }

  private subscribeWs(mrhstId: number): void {
    const subscription = this.rxStompService
      .watch(`/topic/mrhst/${mrhstId}`)
      .subscribe((message) => {
        let type: string;

        try {
          type = JSON.parse(message.body).type;
        } catch (error) {
          return;
        }

        // 주문 발생 알림
        this.posService.delngAlarmEmitter.emit();
        this.playSoundViaDelng();
        this.clearInterval();
        this.setInterval();
      });

    subscriptionMap.set('delng', subscription);
  }

  private setInterval(): void {
    this.interval = setInterval(() => {
      this.playSoundViaDelng();
    }, INTERVAL);
  }

  private clearInterval(): void {
    clearInterval(this.interval);
  }

  private playSoundViaDelng(): void {
    let delng: IDelng;

    this.delngPendingService
      .findPage({ sort: 'delngDttm,desc', size: 1 })
      .pipe(
        tap(({ content }) => {
          [delng] = content;
          [this.latestDelng] = content;
          this.errorCount = 0;
        }),
        filter(() => this.shouldPlayAlarm(delng)),
        tap(() => this.playSound(delng)),
        mergeMap(() => {
          const delgnAddedMsg = this.translateService.instant('MSG.delngAdded');
          const okMsg = this.translateService.instant('ok');

          return this.dialogService.matSnackBar
            .open(delgnAddedMsg, okMsg)
            .onAction();
        }),
        tap(() => {
          const delngDttm = dayjs(delng.delngDttm).format(DATE_FORMAT);
          this.storageService.set(ALARM_CHECK_KEY_NAME, delngDttm);
          this.stopSound();
        }),
        catchError(() => {
          this.errorCount += 1;

          // 3연속 실패하면
          if (this.errorCount >= ERROR_COUNT_LIMIT) {
            this.errorCount = 0;
            // 주문정보를 확인하지 못했습니다. 네트워크 상태를 확인해주세요.
            return this.dialogService.error(
              `注文情報を確認できませんでした。ネットワークの状態を確認してください。`
            );
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  private playSound(delng: IDelng): void {
    const latest = dayjs(this.latestDelngDttm);
    const delngDttm = dayjs(delng.delngDttm);

    if (delngDttm.valueOf() > latest.valueOf()) {
      this.storageService.set(ALARM_CHECK_KEY_NAME, this.latestDelngDttm);
      this.latestDelngDttm = delngDttm.format(DATE_FORMAT);
    }

    const shouldPlayAlarm = this.shouldPlayAlarm(delng);

    if (!shouldPlayAlarm) {
      return;
    }

    if (!this.audio?.paused || this.audio?.currentTime > 0) {
      this.stopSound();
    }

    if (delng.delngDeliv) {
      this.audio = new Audio('/assets/delivery.mp3');
    } else if (delng.delngOrdr) {
      this.audio = new Audio('/assets/takeout.mp3');
    } else {
      return;
    }

    this.audio.removeEventListener('ended', this.audioEndedEvent);
    this.audio.play();
    this.audio.addEventListener('ended', this.audioEndedEvent);
  }

  private stopSound(): void {
    if (!this.audio) {
      return;
    }

    this.audio.pause();
    this.audio.currentTime = 0;
  }

  private shouldPlayAlarm(delng: IDelng): boolean {
    if (!delng) {
      return false;
    }

    const alarmCheckDttm = this.storageService.get(ALARM_CHECK_KEY_NAME);

    if (!alarmCheckDttm) {
      return false;
    }

    const { delngDttm } = delng;
    const shouldPlayAlarm =
      new Date(alarmCheckDttm).valueOf() < new Date(delngDttm).valueOf();

    return shouldPlayAlarm;
  }
}
