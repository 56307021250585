import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PwSummernoteModule } from '../../../../public-api';
import { PwLocaleSummernoteComponent } from './pw-locale-summernote.component';

@NgModule({
  declarations: [PwLocaleSummernoteComponent],
  imports: [CommonModule, PwSummernoteModule, FormsModule],
  exports: [PwLocaleSummernoteComponent],
})
export class PwLocaleSummernoteModule {}
