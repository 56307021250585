<div
  [@routeAnimations]="
    shouldRouteAnimate && routeHelperService.routeDirection$ | async
  "
  (@routeAnimations.start)="onAnimationStart($event)"
  (@routeAnimations.done)="onAnimationsDone($event)"
>
  <router-outlet></router-outlet>
</div>
<div class="test-label" *ngIf="isTest">TEST ONLY</div>
